import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Button,
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Tooltip,
  useDisclosure,
  Select,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { Listing } from "../lib/types/Listing";
import NavBar from "../components/NavBar";
import { setDoc, doc, Timestamp } from "firebase/firestore";
import { auth, db, storage } from "../lib/firebase/config";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { ListingModal } from "../components/ListingModal";

enum FormErrors {
  Empty,
  Title,
  Price,
  Seller,
  Description,
  Category,
  None,
}

function CreateListing() {
  const [newListingForm, setNewListingForm] = useState<Listing>({
    uuid: "",
    title: "",
    seller: auth?.currentUser?.email ?? "",
    price: 0,
    sellerId: auth.currentUser?.uid!,
    description: "",
    datePosted: Timestamp.fromDate(new Date()),
    photos: [],
    category: "",
  });
  const [formError, setFormError] = useState<FormErrors>(FormErrors.None);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const checkForm = () => {
    if (!newListingForm) return FormErrors.Empty;
    else if (!newListingForm.title || newListingForm.title.length < 8)
      return FormErrors.Title;
    else if (!newListingForm.price) return FormErrors.Price;
    else if (!newListingForm.description) return FormErrors.Description;
    else if (!newListingForm.category) return FormErrors.Category;
    else {
      setActiveStep(2);
      return FormErrors.None;
    }
  };

  const [newListingMedia, setNewListingMedia] = useState<FileList | null>(null);

  const uploadPhotos = async (listingUuid: string) => {
    if (!newListingMedia || newListingMedia.length < 1) return [];
    let newPhotoPaths: string[] = [];

    for (let index = 0; index < newListingMedia.length; index++) {
      const photoPath = `${listingUuid}/${uuidv4()}`;
      const storageRef = ref(storage, photoPath);
      await uploadBytes(storageRef, newListingMedia.item(index)!).then(
        (snapshot) => {
          newPhotoPaths.push(photoPath);
        }
      );
    }
    return newPhotoPaths;
  };

  const navigate = useNavigate();

  const submitNewListing = async () => {
    if (!auth.currentUser?.email) return;

    const listingUuid = uuidv4();
    const newPhotos = await uploadPhotos(listingUuid);

    await setDoc(doc(db, "listings", listingUuid), {
      ...newListingForm,
      uuid: listingUuid,
      datePosted: Timestamp.fromDate(new Date()),
      photos: newPhotos,
      seller: auth.currentUser.email,
      status: "pending",
    } as Listing);
    setActiveStep(3);
    setTimeout(() => {
      navigate("/explore");
    }, 6000);
  };

  const steps = [
    { title: "Step 1", description: "Listing Info" },
    { title: "Step 2", description: "Add photos" },
    { title: "Step 3", description: "Confirm and create !" },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  const filesInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <main className="h-screen w-screen flex flex-col items-center justify-center bg-slate-50">
      <NavBar />
      <Stepper
        colorScheme="red"
        className="md:w-[40rem] mb-auto mt-[5rem]"
        index={activeStep}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <div className="w-full h-full flex flex-col gap-[4rem] items-center justify-center">
        {activeStep === 1 ? (
          <div className="flex flex-col gap-4 h-fit w-[25rem] p-10 bg-white shadow-lg rounded-[8px]">
            <FormControl isInvalid={formError === FormErrors.Title}>
              <FormLabel>Title</FormLabel>
              <Input
                type="text"
                value={newListingForm.title}
                onChange={(e) => {
                  setNewListingForm({
                    ...newListingForm,
                    title: e.target.value,
                  });
                }}
              />
              {formError === FormErrors.Title ? (
                <FormErrorMessage>Title is required.</FormErrorMessage>
              ) : null}
            </FormControl>

            <FormControl isInvalid={formError === FormErrors.Price}>
              <FormLabel>Price</FormLabel>
              <Input
                value={newListingForm.price}
                onChange={(e) => {
                  setNewListingForm({
                    ...newListingForm,
                    price: Number(e.target.value),
                  });
                }}
              />
              {formError === FormErrors.Price ? (
                <FormErrorMessage>Price is required.</FormErrorMessage>
              ) : null}
            </FormControl>

            <FormControl isInvalid={formError === FormErrors.Description}>
              <FormLabel>Description</FormLabel>
              <Textarea
                onChange={(e) => {
                  setNewListingForm({
                    ...newListingForm,
                    description: e.target.value,
                  });
                }}
                className="resize-none"
                placeholder="Write your description here..."
              />
              {formError === FormErrors.Description ? (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              ) : null}
            </FormControl>
            <FormControl isInvalid={formError === FormErrors.Category}>
              <Select
                onChange={(e) =>
                  setNewListingForm({
                    ...newListingForm,
                    category: e.target.value,
                  })
                }
                placeholder="Select category"
                className="cursor-pointer"
              >
                <option value="books">Books</option>
                <option value="electronics">Electronics</option>
                <option value="clothing">Clothing</option>
                <option value="miscellaneous">Miscellaneous</option>
              </Select>
              {formError === FormErrors.Category ? (
                <FormErrorMessage>Please choose a category</FormErrorMessage>
              ) : null}
            </FormControl>
            <Button
              onClick={() => {
                setFormError(checkForm());
              }}
              className="mt-4"
              colorScheme="red"
              textColor={"white"}
            >
              Next
            </Button>
          </div>
        ) : null}
        {activeStep === 2 ? (
          <div className="flex flex-col md:flex-row gap-[5rem] h-fit w-fit items-center p-5 rounded-[8px] ">
            <Tooltip
              label="This is a preview of your listing"
              aria-label="A tooltip"
            >
              <div
                onClick={() => {
                  onOpen();
                }}
                className="flex flex-col h-fit w-[22rem] bg-white rounded-[5px] shadow-lg cursor-pointer transition-all overflow-hidden"
              >
                {newListingMedia?.item(0) ? (
                  <img
                    alt={newListingForm.title}
                    className="h-[211px] w-full object-cover"
                    src={URL.createObjectURL(newListingMedia.item(0)!)}
                  />
                ) : (
                  <div className="flex h-[211px] w-full items-center justify-center border-b-[1px] border-slate-300">
                    <img
                      alt="nothing"
                      className="w-[4rem] object-contain"
                      src={"/no-image.png"}
                    />
                  </div>
                )}
                <section className="w-full flex flex-col p-5 gap-3">
                  <h1 className="text-[1.2rem]">{newListingForm.title}</h1>
                  <h2 className="text-[1.2rem] text-main-red">
                    ${newListingForm.price}
                  </h2>
                </section>
              </div>
            </Tooltip>
            <ListingModal
              onClose={onClose}
              isOpen={isOpen}
              selectedListing={newListingForm}
              newListingMedia={newListingMedia}
            />
            <div className="h-full flex flex-col gap-5">
              <div
                onClick={() => {
                  filesInputRef.current?.click();
                }}
                className="cursor-pointer h-fit w-fit border-gray-500/70 border-[1px] p-8 px-12 gap-5 flex flex-col items-center justify-center rounded-[10px] bg-white shadow-lg"
              >
                <img
                  alt="Add media"
                  className=" w-[5rem] object-contain"
                  src="/gallery-add.svg"
                />
                <h1 className="text-[1.3rem] font-semibold">Add photos</h1>
              </div>
              <Tooltip
                label="This feature is currently in development"
                aria-label="A tooltip"
              >
                <div className="cursor-not-allowed h-fit w-fit border-gray-500/70 border-[1px] p-8 px-12 gap-5 flex flex-col items-center justify-center rounded-[10px] bg-white shadow-lg">
                  <img
                    alt="Add media"
                    className=" w-[5rem] object-contain"
                    src="/videocamera-add.svg"
                  />
                  <h1 className="text-[1.3rem] font-semibold">Add Videos</h1>
                </div>
              </Tooltip>
              <input
                multiple
                hidden
                ref={filesInputRef}
                type="file"
                accept="image/png, image/jpeg"
                onChange={(event) => {
                  setNewListingMedia(event.target.files);
                }}
              />
            </div>
          </div>
        ) : null}
        {activeStep === 2 ? (
          <Button
            //   disabled={formError !== FormErrors.None}
            onClick={() => {
              submitNewListing();
            }}
            className="cursor-pointer -mt-[3rem]"
            colorScheme="red"
            textColor={"white"}
          >
            Submit new listing !
          </Button>
        ) : null}
        {activeStep === 3 ? (
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-[3.5rem]">
              Congratulations, your listing was submitted ! 🥳
            </h1>
            <span>Please allow us a few hours to verify your listing...</span>
          </div>
        ) : null}
      </div>
    </main>
  );
}

export default CreateListing;
