import {
  AddIcon,
  UnlockIcon,
  SettingsIcon,
  CalendarIcon,
  ChatIcon,
  LockIcon,
} from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../lib/firebase/config";

function NavBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const createListingClicked = () => {
    navigate("/listings/create");
  };

  const userLogOut = async () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error: Error) => {
        console.log(error);
      });
  };
  return (
    <nav className="h-[4rem] w-full flex items-center shadow-sm z-10 transition-all bg-white gap-4">
      <img
        onClick={() => {
          navigate("/explore");
        }}
        src="/montclair-logo-red.svg"
        className="ml-5 w-[8rem] cursor-pointer"
        alt="Montclair State Univeristy Logo"
      />
      <Badge colorScheme="green">Beta</Badge>
      <Avatar
        onClick={() => {
          onOpen();
        }}
        colorScheme={"red.500"}
        size={"sm"}
        className="ml-auto mr-5 cursor-pointer"
        name={auth.currentUser?.displayName ?? ""}
        src="https://bit.ly/broken-link"
      />
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton className="mt-[4rem] mr-[1rem]"></MenuButton>
        <MenuList>
          <MenuItem onClick={createListingClicked} icon={<AddIcon />}>
            Create a listing
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/listings");
            }}
            icon={<CalendarIcon />}
          >
            My Listings
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/inbox");
            }}
            icon={<ChatIcon />}
          >
            Messages
          </MenuItem>

          {auth.currentUser?.email === "amsafs1@montclair.edu" ? (
            <MenuItem
              onClick={() => {
                navigate("/admin");
              }}
              icon={<LockIcon />}
            >
              Admin
            </MenuItem>
          ) : null}
          <MenuItem icon={<SettingsIcon />}>Settings</MenuItem>
          <MenuItem onClick={userLogOut} icon={<UnlockIcon />}>
            Sign Out
          </MenuItem>
        </MenuList>
      </Menu>
    </nav>
  );
}

export default NavBar;
