import { Avatar, Divider } from "@chakra-ui/react";
import { auth, db } from "../lib/firebase/config";
import { useEffect, useRef, useState } from "react";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { Conversation } from "../lib/types/Conversation";
import NavBar from "../components/NavBar";

type Props = {
  currentConvo: Conversation | undefined;
  conversations: Conversation[];
  changeConversation: React.Dispatch<
    React.SetStateAction<Conversation | undefined>
  >;
};
function SideBar({ currentConvo, conversations, changeConversation }: Props) {
  return (
    <div className="h-full w-[25rem] flex flex-col items-center  bg-white">
      <h1 className="px-8 py-5 text-[1.4rem] font-medium self-start">
        Messages
      </h1>
      {conversations
        ? conversations.map((convo, index) => {
            return (
              <div
                onClick={() => {
                  changeConversation(convo);
                }}
                key={index}
                className="h-[5rem] w-full flex items-center gap-4 hover:bg-slate-50 cursor-pointer"
              >
                <Avatar
                  size={"md"}
                  className="ml-4"
                  name={
                    convo?.participants.filter(
                      (user) => user !== auth.currentUser?.email
                    )[0]
                  }
                  src="https://bit.ly/broken-link"
                />
                <h2 className="text-[1.1rem] font-medium">
                  {
                    convo.participants.filter(
                      (user) => user !== auth.currentUser?.email
                    )[0]
                  }
                </h2>
              </div>
            );
          })
        : null}
    </div>
  );
}

export default function Messages() {
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [conversations, setConversations] = useState<Conversation[] | null>(
    null
  );

  const [currentConversation, setCurrentConversation] =
    useState<Conversation>();

  useEffect(() => {
    const q = query(
      collection(db, "conversations"),
      where("participantsId", "array-contains", auth.currentUser?.uid),
      orderBy("lastUpdated", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const convos: Conversation[] = [];
      querySnapshot.forEach((doc) => {
        convos.push(doc.data() as Conversation);
      });
      setConversations(convos);
      if (convos.length > 0) {
        setCurrentConversation(convos[0]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [currentConversation?.messages]);

  const sendMessage = async (e: any) => {
    e.preventDefault();

    if (!currentConversation?.id || !currentMessage) return;

    const convoRef = doc(db, "conversations", currentConversation.id);

    await updateDoc(convoRef, {
      messages: [
        ...currentConversation.messages,
        {
          date: new Date(),
          sender: auth.currentUser?.email,
          content: currentMessage,
        },
      ],
      lastUpdated: Timestamp.fromDate(new Date()),
    });

    scrollRef.current?.scrollIntoView({ behavior: "smooth" });

    setCurrentMessage("");
  };

  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <main className="h-screen w-screen flex flex-col bg-slate-50">
      <NavBar />
      <div className="h-full w-full flex overflow-hidden">
        <SideBar
          conversations={conversations ?? []}
          changeConversation={setCurrentConversation}
          currentConvo={currentConversation}
        />
        <Divider orientation="vertical" />
        <div className="h-full w-full flex flex-col">
          <div className="h-[5rem] w-full flex justify-center items-center gap-4 bg-white">
            <Avatar
              size={"md"}
              className="ml-4"
              name={
                currentConversation?.participants.filter(
                  (user) => user !== auth.currentUser?.email
                )[0]
              }
              src="https://bit.ly/broken-link"
            />
            <h2 className="text-[1.1rem] font-medium">
              {
                currentConversation?.participants.filter(
                  (user) => user !== auth.currentUser?.email
                )[0]
              }
            </h2>
          </div>
          <div className="h-[85%] mt-auto flex flex-col p-3 overflow-scroll ">
            <div className="mt-auto flex flex-col">
              {currentConversation?.messages.map((message, index) => {
                return message.sender === auth.currentUser?.email ? (
                  // <div key={index} className={`ml-auto bg-main-red p-2`}>
                  //   <span className="text-[1.1rem] py-2 px-3  text-white rounded-[10px]">
                  //     {message.content}
                  //   </span>
                  // </div>
                  <div key={index} className="ml-auto p-4">
                    <span className="text-white text-[1.1rem] py-2 px-3 bg-main-red rounded-[10px]">
                      {message.content}
                    </span>
                  </div>
                ) : (
                  <div key={index} className="p-4">
                    <span className="text-[1.1rem] py-2 px-3 bg-gray-200 text-black rounded-[10px]">
                      {message.content}
                    </span>
                  </div>
                );
              })}
            </div>

            <div ref={scrollRef}></div>
          </div>
          <form className="w-full h-[3rem] flex rounded-t-md overflow-hidden">
            <input
              onChange={(e) => {
                setCurrentMessage(e.target.value);
              }}
              value={currentMessage}
              placeholder="Type in a message..."
              className="h-full w-[90%] indent-5 outline-none"
            />
            <button
              onClick={sendMessage}
              className="bg-main-red w-[10%] text-white font-medium"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </main>
  );
}
