import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Explore } from "./pages/Explore";
import { ChakraProvider } from "@chakra-ui/react";
import CreateListing from "./pages/CreateListing";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { auth } from "./lib/firebase/config";
import ProtectedRoute from "./components/ProtectedRoute";
import { AdminDashboard } from "./pages/AdminDashboard";
import Messages from "./pages/Messages";
import { MyListings } from "./pages/MyListings";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ChakraProvider>
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route element={<ProtectedRoute auth={auth} />}>
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/inbox" element={<Messages />} />
          <Route path="/listings/create" element={<CreateListing />} />
          <Route path="/listings" element={<MyListings />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
);

reportWebVitals();
