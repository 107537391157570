import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { auth, db, storage } from "../lib/firebase/config";
import { Listing } from "../lib/types/Listing";
import NavBar from "../components/NavBar";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { getDownloadURL, ref } from "firebase/storage";
import { AddIcon, CloseIcon, Search2Icon } from "@chakra-ui/icons";
import { FirebaseError } from "firebase/app";
import { ListingModal } from "../components/ListingModal";
import { LoadingPage } from "../components/LoadingPage";
import { ListingCard } from "../components/ListingCard";
import { AlerModal } from "../components/AlertModal";
import { Conversation } from "../lib/types/Conversation";
import { useNavigate } from "react-router-dom";
import { AffilitationDisclaimer } from "../components/AffiliationDisclaimer";

export function Explore() {
  const navigate = useNavigate();

  const [isUserVerified, setIsUserVerified] = useState<boolean>(false);
  const [listings, setListings] = useState<Listing[] | null>(null);
  const [filteredListings, setfilteredListings] = useState<Listing[] | null>(
    null
  );

  const [searchTerms, setSearchTerms] = useState<string[]>([]);

  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<string[]>([]);

  const { onOpen, onClose, isOpen } = useDisclosure();

  const fetchListings = async () => {
    const listingsRef = collection(db, "listings");

    const q = query(
      listingsRef,
      where("status", "==", "verified"),
      orderBy("datePosted", "desc")
    );

    try {
      const fetchedListings = await getDocs(q);
      const listingArr: Listing[] = await Promise.all(
        fetchedListings.docs.map(async (fetchedListing) => {
          const listing: Listing = fetchedListing.data() as Listing;
          const photoUrls: string[] = await Promise.all(
            listing.photos.map(async (photoPath) => {
              return await getImageUrl(photoPath);
            })
          );

          return { ...listing, photos: photoUrls };
        })
      );
      setIsUserVerified(!!auth.currentUser?.emailVerified);
      setListings(listingArr);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const err = error as FirebaseError;
      console.log(err);
      if (err.code === "permission-denied") setIsUserVerified(false);
    }
  };

  const getImageUrl = async (photoPath: string) => {
    try {
      const storageRef = ref(storage, photoPath);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      return "no-image.png";
    }
  };

  const contactSeller = async () => {
    if (
      !selectedListing ||
      !auth.currentUser?.uid ||
      auth.currentUser?.uid === selectedListing.sellerId
    ) {
      return;
    }
    const convId = `${auth.currentUser?.uid}${selectedListing.sellerId}`;
    const newConvRef1 = doc(db, "conversations", convId);

    const docSnap1 = await getDoc(newConvRef1);

    const convId2 = `${selectedListing.sellerId}${auth.currentUser?.uid}`;
    const newConvRef2 = doc(db, "conversations", convId2);

    const docSnap2 = await getDoc(newConvRef2);

    if (!docSnap1.exists() && !docSnap2.exists()) {
      const newConversation: Conversation = {
        messages: [],
        id: convId,
        participants: [auth.currentUser?.email!, selectedListing.seller],
        participantsId: [auth.currentUser?.uid!, selectedListing.sellerId],
        lastUpdated: Timestamp.fromDate(new Date()),
      };
      await setDoc(newConvRef1, newConversation);
    } else if (!docSnap1.exists()) {
      await updateDoc(newConvRef2, {
        lastUpdated: Timestamp.fromDate(new Date()),
      });
    } else {
      await updateDoc(newConvRef1, {
        lastUpdated: Timestamp.fromDate(new Date()),
      });
    }
    navigate("/inbox");
  };

  useEffect(() => {
    fetchListings();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!listings) return;
    if (filters.length < 1 && searchTerms.length < 1) {
      setfilteredListings(null);
      return;
    }

    let newFilteredListings: Listing[] = listings;

    if (filters.length > 0) {
      newFilteredListings = listings?.filter((listing) =>
        filters.includes(listing.category)
      );
    }

    if (searchTerms.length > 0) {
      newFilteredListings = newFilteredListings.filter((listing) => {
        for (var i = 0; i < searchTerms.length; i++) {
          if (
            listing.title
              .toLocaleLowerCase()
              .includes(searchTerms[i].toLocaleLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    }

    setfilteredListings(newFilteredListings);

    setIsLoading(false);
    // eslint-disable-next-line
  }, [filters, searchTerms]);

  return (
    <main className="h-screen w-screen flex flex-col bg-slate-50">
      <NavBar />
      <div className="flex flex-col gap-2 md:flex-row px-5 py-2 mt-4 justify-between">
        <h1 className="text-[2rem] font-semibold">Explore</h1>
        <div className="flex w-[20rem]">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="black" />
            </InputLeftElement>
            <Input
              onChange={(e) => {
                setSearchTerms(e.target.value.match(/[^ ]+/g) ?? []);
              }}
              bgColor={"white"}
              focusBorderColor="red.500"
              type="text"
              placeholder="Search anything..."
            />
          </InputGroup>
        </div>
      </div>
      <div className="flex gap-4 h-fit w-fit px-5 py-2 transition-all">
        {filters.map((currentFilter: string, index: number) => {
          return (
            <div
              key={index}
              onClick={() => {
                setFilters(
                  filters.filter((filter) => filter !== currentFilter)
                );
              }}
              className="cursor-pointer flex items-center  gap-2 bg-main-red/80 px-3 py-1 text-white rounded-[10px]"
            >
              {currentFilter}
              <CloseIcon boxSize={3} />
            </div>
          );
        })}

        <div
          onClick={() => {
            if (filters.length < 3) onOpen();
          }}
          className="cursor-pointer flex items-center gap-2 bg-white border-main-red border-[1px] px-3 py-1 text-main-red rounded-[10px]"
        >
          Add a filter
          <AddIcon boxSize={3} />
        </div>

        <Menu onClose={onClose} isOpen={isOpen && filters.length < 3}>
          <MenuButton className="-ml-[4rem] mr-[1rem]"></MenuButton>
          <MenuList>
            {!filters.includes("books") ? (
              <MenuItem
                onClick={() => {
                  setFilters([...filters, "books"]);
                }}
              >
                Books
              </MenuItem>
            ) : null}
            {!filters.includes("electronics") ? (
              <MenuItem
                onClick={() => {
                  setFilters([...filters, "electronics"]);
                }}
              >
                Electronics
              </MenuItem>
            ) : null}
            {!filters.includes("clothing") ? (
              <MenuItem
                onClick={() => {
                  setFilters([...filters, "clothing"]);
                }}
              >
                Clothing
              </MenuItem>
            ) : null}
            {!filters.includes("miscellaneous") ? (
              <MenuItem
                onClick={() => {
                  setFilters([...filters, "miscellaneous"]);
                }}
              >
                Miscellaneous
              </MenuItem>
            ) : null}
          </MenuList>
        </Menu>
      </div>

      {(!isLoading && filteredListings) || listings ? (
        filteredListings ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-12 flex-wrap h-fit w-full bg-slate-50 px-5 py-7 ">
            {filteredListings.map((listing, index) => {
              return (
                <div key={index}>
                  <ListingCard
                    listing={listing}
                    index={index}
                    setSelectedListing={setSelectedListing}
                  />
                </div>
              );
            })}
            <ListingModal
              isOpen={!!selectedListing}
              setSelectedListing={setSelectedListing}
              selectedListing={selectedListing!}
              contactSeller={contactSeller}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-12 flex-wrap h-fit w-full bg-slate-50 px-5 py-7 ">
            {listings?.map((listing, index) => {
              return (
                <div key={index}>
                  <ListingCard
                    listing={listing}
                    index={index}
                    setSelectedListing={setSelectedListing}
                  />
                </div>
              );
            })}
            <ListingModal
              isOpen={!!selectedListing}
              setSelectedListing={setSelectedListing}
              selectedListing={selectedListing!}
              contactSeller={contactSeller}
            />
          </div>
        )
      ) : (
        <LoadingPage />
      )}

      <AlerModal isOpen={!isLoading && !isUserVerified} />
      <AffilitationDisclaimer />
    </main>
  );
}
