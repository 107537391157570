import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tag,
} from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import { Listing } from "../lib/types/Listing";

type Props = {
  onClose?: () => void;
  isOpen: boolean;
  selectedListing: Listing;
  setSelectedListing?: React.Dispatch<React.SetStateAction<Listing | null>>;
  contactSeller?: (() => Promise<void>) | null;
  removeListing?: (() => Promise<void>) | null;
  newListingMedia?: FileList | null;
};

export function ListingModal({
  isOpen,
  onClose,
  selectedListing,
  setSelectedListing,
  contactSeller,
  newListingMedia,
  removeListing,
}: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (onClose) onClose();
        if (setSelectedListing) setSelectedListing(null);
      }}
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="flex items-center gap-3">
          {selectedListing?.title}{" "}
          {removeListing && selectedListing ? (
            selectedListing.status === "pending" ? (
              <Tag size={"md"} variant="solid" colorScheme="orange">
                {selectedListing.status}
              </Tag>
            ) : (
              <Tag size={"md"} variant="solid" colorScheme="teal">
                {selectedListing.status}
              </Tag>
            )
          ) : null}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-5 ">
          {(newListingMedia && newListingMedia) ||
          (selectedListing && selectedListing.photos.length > 0) ? (
            <Carousel showThumbs={false}>
              {newListingMedia && newListingMedia?.length > 0
                ? Array.from(newListingMedia).map((photo, index) => {
                    return (
                      <div key={index} className="flex h-[22rem]">
                        <img
                          alt={selectedListing.title}
                          src={URL.createObjectURL(
                            newListingMedia?.item(index)!,
                          )}
                          className="z-10 object-contain"
                        />
                        <img
                          alt={selectedListing.title}
                          src={URL.createObjectURL(
                            newListingMedia?.item(index)!,
                          )}
                          className="absolute object-cover blur-lg"
                        />
                      </div>
                    );
                  })
                : selectedListing?.photos.map((photo, index) => {
                    return (
                      <div key={index} className="flex h-[22rem]">
                        <img
                          alt={selectedListing.title}
                          src={photo}
                          className="z-10 object-contain"
                        />
                        <img
                          alt={selectedListing.title}
                          src={photo}
                          className="absolute object-cover blur-lg"
                        />
                      </div>
                    );
                  })}
            </Carousel>
          ) : (
            <div className="flex h-[211px] w-full items-center justify-center border-b-[1px] border-slate-300">
              <img
                alt="nothing"
                className="w-[4rem] object-contain"
                src={"/no-image.png"}
              />
            </div>
          )}
          <div className="flex flex-col gap-1">
            <h2 className="text-[1.1rem] font-medium">Description</h2>
            <p>{selectedListing?.description}</p>
          </div>
          <div className="flex flex-col">
            <h2 className="text-[1.1rem] font-medium">Seller</h2>
            <p>{selectedListing?.seller}</p>
          </div>
          <div className="flex flex-col gap-1">
            <h2 className="text-[1.1rem] font-medium">Category</h2>
            <p>{selectedListing?.category}</p>
          </div>
          <div className="flex flex-col gap-1">
            <h2 className="text-[1.1rem] font-medium">Price</h2>
            <p>${selectedListing?.price}</p>
          </div>
          <div className="flex flex-col">
            <h2 className="text-[1.1rem] font-medium">Date posted</h2>
            <p>{selectedListing?.datePosted?.toDate().toDateString()}</p>
          </div>
        </ModalBody>
        <ModalFooter>
          {contactSeller ? (
            <button
              onClick={() => contactSeller()}
              className="bg-main-red cursor-pointer rounded-[5px] p-2 font-medium text-white"
            >
              Contact seller
            </button>
          ) : null}
          {removeListing ? (
            <button
              onClick={() => removeListing()}
              className="bg-main-red cursor-pointer rounded-[5px] p-2 font-medium text-white"
            >
              Delete
            </button>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
