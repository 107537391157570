import {
  collection,
  query,
  orderBy,
  getDocs,
  where,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db, storage } from "../lib/firebase/config";
import { Listing } from "../lib/types/Listing";
import NavBar from "../components/NavBar";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { deleteObject, getDownloadURL, ref } from "firebase/storage";
import { Search2Icon } from "@chakra-ui/icons";
import { Carousel } from "react-responsive-carousel";

export function AdminDashboard() {
  const [listings, setListings] = useState<Listing[] | null>(null);
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchListings = async () => {
    const listingsRef = collection(db, "listings");
    const q = query(
      listingsRef,
      where("status", "==", "pending"),
      orderBy("datePosted", "desc")
    );
    const fetchedListings = await getDocs(q);

    const listingArr: Listing[] = await Promise.all(
      fetchedListings.docs.map(async (fetchedListing) => {
        const listing: Listing = fetchedListing.data() as Listing;
        const photoUrls: string[] = await Promise.all(
          listing.photos.map(async (photoPath) => {
            return await getImageUrl(photoPath);
          })
        );

        return { ...listing, photos: photoUrls };
      })
    );

    setListings(listingArr);
    setIsLoading(false);
  };

  const getImageUrl = async (photoPath: string) => {
    try {
      const storageRef = ref(storage, photoPath);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      return "no-image.png";
    }
  };

  const approveListing = async () => {
    if (!selectedListing || !listings) return;
    const listingRef = doc(db, "listings", selectedListing.uuid);

    // Set the "capital" field of the city 'DC'
    await updateDoc(listingRef, {
      status: "verified",
    });

    let newListings = listings.filter(
      (listing) => listing.uuid !== selectedListing.uuid
    );

    setListings(newListings);
    onClose();
  };

  const rejectListing = async () => {
    if (!selectedListing || !listings) return;
    const listingRef = doc(db, "listings", selectedListing.uuid);

    await deleteDoc(listingRef);

    selectedListing.photos.forEach(async (photo) => {
      // Create a reference to the file to delete
      const photoRef = ref(storage, photo);

      // Delete the file
      await deleteObject(photoRef)
        .then(() => {
          console.log("photo deleted !");
        })
        .catch((error) => {
          console.log(error);
        });
    });

    let newListings = listings.filter(
      (listing) => listing.uuid !== selectedListing.uuid
    );
    setListings(newListings);
    onClose();
  };

  useEffect(() => {
    fetchListings();
    // eslint-disable-next-line
  }, []);

  return (
    <main className="h-screen w-screen flex flex-col bg-slate-50">
      <NavBar />
      <div className="flex px-5 py-2 mt-4 justify-between items-center">
        <h1 className="text-[2rem] font-semibold">Admin Dashboard</h1>
        <div className="flex w-[20rem]">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="black" />
            </InputLeftElement>
            <Input
              bgColor={"white"}
              focusBorderColor="red.500"
              type="text"
              placeholder="Search anything..."
            />
          </InputGroup>
        </div>
      </div>
      {!isLoading && listings ? (
        <div className="flex  gap-12 flex-wrap h-fit w-full bg-slate-50 px-5 py-7 ">
          {listings.map((listing, index) => {
            return (
              <div
                onClick={() => {
                  setSelectedListing(listing);
                  onOpen();
                }}
                key={index}
                className="flex flex-col h-fit w-[20rem] bg-white rounded-[5px] shadow-lg cursor-pointer hover:-translate-y-2 transition-all overflow-hidden"
              >
                {listing.photos[0] ? (
                  <img
                    alt={listing.title}
                    className="h-[211px] w-full object-cover"
                    src={listing.photos[0]}
                  />
                ) : null}
                <section className="w-full flex flex-col p-4 gap-3">
                  <h1 className="text-[1.2rem]">{listing.title}</h1>
                  <div className="flex justify-between">
                    <h2 className="text-[1.2rem] text-main-red">
                      ${listing.price}
                    </h2>
                  </div>
                </section>
              </div>
            );
          })}{" "}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{selectedListing?.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody className="flex flex-col gap-5">
                <Carousel showThumbs={false} className="">
                  {selectedListing?.photos.map((photo, index) => {
                    return (
                      <div key={index} className="h-[270px] flex">
                        <img
                          alt={selectedListing.title}
                          src={photo}
                          className="object-cover"
                        />
                      </div>
                    );
                  })}
                </Carousel>
                <div className="flex flex-col gap-1">
                  <h2 className="text-[1.1rem] font-medium">Description</h2>
                  <p>{selectedListing?.description}</p>
                </div>{" "}
                <div className="flex flex-col">
                  <h2 className="text-[1.1rem] font-medium">Seller</h2>
                  <p>{selectedListing?.seller}</p>
                </div>
                <div className="flex flex-col">
                  <h2 className="text-[1.1rem] font-medium">Date posted</h2>
                  <p>{selectedListing?.datePosted?.toDate().toDateString()}</p>
                </div>
              </ModalBody>
              <ModalFooter className="flex gap-3">
                <Button
                  onClick={() => approveListing()}
                  colorScheme="green"
                  size="sm"
                >
                  Approve
                </Button>
                <Button
                  onClick={() => rejectListing()}
                  colorScheme="red"
                  size="sm"
                >
                  Reject
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner size="xl" color="red.500" />
        </div>
      )}
    </main>
  );
}
