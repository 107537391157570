import { useState } from "react";

import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../lib/firebase/config";
import {
  FormControl,
  FormErrorMessage,
  Input,
  useToast,
} from "@chakra-ui/react";
import { FirebaseError } from "firebase/app";
import { InfoIcon } from "@chakra-ui/icons";
import { AffilitationDisclaimer } from "../components/AffiliationDisclaimer";

enum FormErrors {
  InvalidEmail,
  NoPassword,
  Empty,
  None,
}

export default function Login() {
  const toast = useToast();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [formError, setFormError] = useState<FormErrors>(FormErrors.Empty);

  const sendLoginRequest = () => {
    if (!email || !password) return;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        if (userCredential.user.emailVerified) {
          navigate("/explore");
        } else {
          toast({
            title: "Email verification",
            description:
              "Please verify your email address before accessing your account.",
            status: "error",
            duration: 7000,
            isClosable: true,
          });
        }
      })
      .catch((error: FirebaseError) => {
        if (error.code === "auth/invalid-credential") {
          toast({
            title: "Invalid credentials",
            description: "Incorrect email or password.",
            status: "error",
            duration: 7000,
            isClosable: true,
          });
        }
      });

    setFormError(FormErrors.None);
  };

  const checkRegistrationForm = (e: any) => {
    e.preventDefault();

    if (!email) setFormError(FormErrors.InvalidEmail);
    else if (!password) setFormError(FormErrors.NoPassword);
    else {
      sendLoginRequest();
    }
  };

  return (
    <main className="flex flex-col h-screen w-screen items-center justify-center bg-[#f9f9fb]">
      <div className="h-auto w-auto p-5 gap-10 flex flex-col items-center mt-auto">
        <section className="flex flex-col items-center gap-2">
          <h1 className={" font-bold text-[32px]"}>Welcome back !</h1>
          <span className="text-[14px] text-slate-500">
            Do not have an account yet?{" "}
            <Link to="/register">
              <span className=" text-main-red hover:underline hover:cursor-pointer">
                Create account
              </span>{" "}
            </Link>
          </span>
        </section>
        <form className="flex flex-col items-center gap-5 w-[25rem] h-auto shadow-md px-7 py-10 rounded-md bg-white">
          <FormControl isInvalid={formError === FormErrors.InvalidEmail}>
            <Input
              name="email"
              type="email"
              placeholder="johndoe1@montclair.edu"
              value={email ?? ""}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {formError === FormErrors.InvalidEmail ? (
              <FormErrorMessage>
                A valid MSU email is required.
              </FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl isInvalid={formError === FormErrors.NoPassword}>
            <Input
              name="password"
              type="password"
              value={password ?? ""}
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {formError === FormErrors.NoPassword ? (
              <FormErrorMessage>Please enter your password.</FormErrorMessage>
            ) : null}
          </FormControl>
          <div className="flex gap-2">
            <InfoIcon color={"gray"} />
            <span className=" text-gray-400 text-[11px]">
              If autofill does not work type in your credentials manually.
            </span>
          </div>
          <button
            onClick={(e) => {
              checkRegistrationForm(e);
            }}
            className="mt-3 bg-main-red w-full h-[2.3rem] rounded-[5px] text-white font-semibold"
          >
            Sign in
          </button>
        </form>
      </div>
      <AffilitationDisclaimer />
    </main>
  );
}
