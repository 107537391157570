import { useDisclosure } from "@chakra-ui/react";
import { Listing } from "../lib/types/Listing";

type Props = {
  listing: Listing;
  setSelectedListing: React.Dispatch<React.SetStateAction<Listing | null>>;
  index: number;
};
export function ListingCard({ setSelectedListing, listing, index }: Props) {
  const { onOpen } = useDisclosure();

  return (
    <div
      onClick={() => {
        setSelectedListing(listing);
        onOpen();
      }}
      key={index}
      className="flex flex-col h-[20rem] w-auto bg-white rounded-[5px] shadow-lg cursor-pointer hover:-translate-y-2 transition-all overflow-hidden"
    >
      {listing.photos[0] ? (
        <div className="flex h-[14rem] w-full items-center justify-center">
          <img
            className="h-full w-full object-cover"
            src={listing.photos[0]}
            alt={listing.title}
          />
        </div>
      ) : (
        <div className="flex h-[14rem] w-full items-center justify-center border-b-[1px] border-slate-300">
          <img
            alt="nothing"
            className="w-[4rem] object-contain"
            src={"/no-image.png"}
          />
        </div>
      )}
      <section className="w-full flex flex-col py-5 px-4 gap-2">
        <h1 className="text-[1.1rem]">
          {listing.title.length > 34
            ? listing.title.slice(0, 29) + "..."
            : listing.title}
        </h1>
        <div className="flex justify-between">
          <h2 className="text-[1.2rem] text-main-red">${listing.price}</h2>
        </div>
      </section>
    </div>
  );
}
