import { useState } from "react";

import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { Link } from "react-router-dom";
import { auth } from "../lib/firebase/config";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  useToast,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { AffilitationDisclaimer } from "../components/AffiliationDisclaimer";

enum FormErrors {
  FullName,
  InvalidEmail,
  PswdTooShort,
  Empty,
  None,
}

export default function Register() {
  const toast = useToast();
  const [fullName, setFullName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);

  const [formError, setFormError] = useState<FormErrors>(FormErrors.Empty);

  const sendRegisterRequest = async () => {
    if (!email || !password) return;

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (user) => {
        await updateProfile(auth.currentUser!, {
          displayName: fullName,
        })
          .then(() => {
            console.log("Profile updated !");
          })
          .catch((error) => {
            console.log(error);
          });
        sendEmailVerf();
        toast({
          title: "Account successfully created",
          description:
            "Please verify your email address before accessing your account.",
          status: "success",
          duration: 7000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sendEmailVerf = async () => {
    if (!auth.currentUser) return;
    await sendEmailVerification(auth.currentUser);
  };

  const checkRegistrationForm = (e: any) => {
    e.preventDefault();
    const validEmailRegex = /^[a-zA-Z0-9._-]+@montclair\.edu$/;
    if (!fullName) setFormError(FormErrors.FullName);
    else if (!email || !validEmailRegex.test(email))
      setFormError(FormErrors.InvalidEmail);
    else if (!password || password.length < 5)
      setFormError(FormErrors.PswdTooShort);
    else {
      sendRegisterRequest();
    }
  };
  return (
    <main className="flex h-screen w-screen flex-col items-center justify-center bg-[#f9f9fb]">
      <div className="mt-auto flex h-auto w-auto flex-col items-center gap-10 p-5">
        <section className="flex flex-col items-center gap-2">
          <h1 className={" text-[32px] font-bold"}>
            Let's create you an account !
          </h1>
          <span className="text-[14px] text-slate-500">
            Already have an account ?{" "}
            <Link to="/login">
              <span className=" text-main-red hover:cursor-pointer hover:underline">
                Log In
              </span>{" "}
            </Link>
          </span>
        </section>
        <form className="flex h-auto w-[25rem] flex-col items-center gap-5 rounded-md bg-white px-7 py-10 shadow-md">
          <FormControl isInvalid={formError === FormErrors.FullName}>
            <Input
              type="text"
              placeholder="Your Name"
              onChange={(e) => {
                setFullName(e.target.value);
              }}
            />
            {formError !== FormErrors.FullName ? (
              <FormHelperText>
                Don't worry, we'll never share it.
              </FormHelperText>
            ) : null}
            {formError === FormErrors.FullName ? (
              <FormErrorMessage>Please enter your full name.</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl isInvalid={formError === FormErrors.InvalidEmail}>
            <Input
              type="email"
              placeholder="Your MSU email address"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {formError === FormErrors.InvalidEmail ? (
              <FormErrorMessage>
                A valid MSU email is required.
              </FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl isInvalid={formError === FormErrors.PswdTooShort}>
            <Input
              type="password"
              placeholder="Enter a password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {formError === FormErrors.PswdTooShort ? (
              <FormErrorMessage>
                Your password is too short. 5 characters min.
              </FormErrorMessage>
            ) : null}
          </FormControl>
          <div className="flex gap-2">
            <InfoIcon color={"gray"} />
            <span className=" text-[11px] text-gray-400">
              Not affiliated with MSU's official Nest System
            </span>
          </div>
          <button
            onClick={(e) => {
              checkRegistrationForm(e);
            }}
            className="bg-main-red mt-3 h-[2.3rem] w-full rounded-[5px] font-semibold text-white"
          >
            Create my account !
          </button>
        </form>
      </div>
      <AffilitationDisclaimer />
    </main>
  );
}
