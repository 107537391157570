import { Spinner } from "@chakra-ui/react";
import { Auth, User, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

type Props = {
  auth: Auth;
};
function ProtectedRoute({ auth }: Props) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  if (loading) return <Spinner />;

  return user ? <Outlet /> : <Navigate to="/login" replace />;
}

export default ProtectedRoute;
