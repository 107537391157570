import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { sendEmailVerification } from "firebase/auth";
import { useRef } from "react";
import { auth } from "../lib/firebase/config";

type Props = {
  isOpen: boolean;
};

/**
 * This is an alert modal for users who have not verified their accounts
 * */
export function AlerModal({ isOpen }: Props) {
  const toast = useToast();
  const { onClose } = useDisclosure();
  const sendEmailVerfRef = useRef<HTMLButtonElement | null>(null);

  const reSendEmailVerf = async () => {
    if (!auth.currentUser) return;
    await sendEmailVerification(auth.currentUser).then(() => {
      toast({
        title: "Sent",
        description: "Email was successfully sent to your email.",
        status: "success",
        duration: 7000,
        isClosable: true,
      });
    });
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={sendEmailVerfRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Unverified account !
          </AlertDialogHeader>

          <AlertDialogBody>
            Please make sure that you have verified your email address beforing
            accessing the platform then refresh the page.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              onClick={() => {
                reSendEmailVerf();
              }}
              ref={sendEmailVerfRef}
              colorScheme="red"
              ml={3}
            >
              Re-send email
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
