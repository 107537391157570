import { useDisclosure, Spinner, Tag } from "@chakra-ui/react";
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { ref, getDownloadURL, deleteObject } from "firebase/storage";
import { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import { auth, db, storage } from "../lib/firebase/config";
import { Listing } from "../lib/types/Listing";
import { ListingModal } from "../components/ListingModal";

export function MyListings() {
  const [listings, setListings] = useState<Listing[] | null>(null);
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { onOpen, onClose } = useDisclosure();

  const fetchListings = async () => {
    const listingsRef = collection(db, "listings");
    const q = query(
      listingsRef,
      where("seller", "==", auth.currentUser?.email),
      orderBy("datePosted", "desc")
    );
    const fetchedListings = await getDocs(q);

    const listingArr: Listing[] = await Promise.all(
      fetchedListings.docs.map(async (fetchedListing) => {
        const listing: Listing = fetchedListing.data() as Listing;
        const photoUrls: string[] = await Promise.all(
          listing.photos.map(async (photoPath) => {
            return await getImageUrl(photoPath);
          })
        );

        return { ...listing, photos: photoUrls };
      })
    );

    setListings(listingArr);
    setIsLoading(false);
  };

  const getImageUrl = async (photoPath: string) => {
    try {
      const storageRef = ref(storage, photoPath);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      return "no-image.png";
    }
  };

  const removeListing = async () => {
    if (!selectedListing || !listings) return;
    const listingRef = doc(db, "listings", selectedListing.uuid);

    await deleteDoc(listingRef);

    selectedListing.photos.forEach(async (photo) => {
      // Create a reference to the file to delete
      const photoRef = ref(storage, photo);

      // Delete the file
      await deleteObject(photoRef)
        .then(() => {
          console.log("photo deleted !");
        })
        .catch((error) => {
          console.log(error);
        });
    });

    let newListings = listings.filter(
      (listing) => listing.uuid !== selectedListing.uuid
    );
    setListings(newListings);
    setSelectedListing(null);
    onClose();
  };

  useEffect(() => {
    fetchListings();
    // eslint-disable-next-line
  }, []);

  return (
    <main className="h-screen w-screen flex flex-col bg-slate-50">
      <NavBar />
      <div className="flex px-5 py-2 mt-4 justify-between items-center">
        <h1 className="text-[2rem] font-semibold">My Listings</h1>
      </div>
      {!isLoading && listings ? (
        <div className="flex  gap-12 flex-wrap h-fit w-full bg-slate-50 px-5 py-7 ">
          {listings.map((listing, index) => {
            return (
              <div
                onClick={() => {
                  setSelectedListing(listing);
                  onOpen();
                }}
                key={index}
                className="flex flex-col h-fit w-[20rem] bg-white rounded-[5px] shadow-lg cursor-pointer hover:-translate-y-2 transition-all overflow-hidden"
              >
                {listing.photos[0] ? (
                  <img
                    alt={listing.title}
                    className="h-[211px] w-full object-cover"
                    src={listing.photos[0]}
                  />
                ) : null}
                <section className="w-full flex flex-col p-4 gap-3">
                  <h1 className="text-[1.2rem]">{listing.title}</h1>
                  <div className="flex justify-between">
                    <h2 className="text-[1.2rem] text-main-red">
                      ${listing.price}
                    </h2>
                    {listing.status === "pending" ? (
                      <Tag size={"md"} variant="solid" colorScheme="orange">
                        {listing.status}
                      </Tag>
                    ) : (
                      <Tag size={"md"} variant="solid" colorScheme="teal">
                        {listing.status}
                      </Tag>
                    )}
                  </div>
                </section>
              </div>
            );
          })}{" "}
          <ListingModal
            isOpen={!!selectedListing}
            setSelectedListing={setSelectedListing}
            selectedListing={selectedListing!}
            removeListing={removeListing}
          />
        </div>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner size="xl" color="red.500" />
        </div>
      )}
    </main>
  );
}
